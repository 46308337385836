import React from "react";
import Title from "./Title";
import { FaAngleDoubleRight } from "react-icons/fa";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";

const query = graphql`
  {
    allStrapiJob(sort: { fields: data___attributes___index, order: DESC }) {
      nodes {
        data {
          attributes {
            company
            date
            desc {
              id
              name
            }
            position
            index
            location
          }
        }
      }
    }
  }
`;

const Jobs = () => {
  const data = useStaticQuery(query);
  const {
    allStrapiJob: { nodes: jobs },
  } = data;
  // Sorting and ordering does not work in strapi plugin v4.
  // Remove after plugin fixes
  const reverseJobs = jobs[0].data.slice(0).reverse();
  const [value, setValue] = React.useState(0);
  const { company, position, date, desc } = reverseJobs[value].attributes;
  return (
    <section className="section jobs bg-black">
      <Title title="experience" />
      <div className="jobs-center">
        {/* btn container */}
        <div className="btn-container">
          {reverseJobs.map((item, index) => {
            return (
              <button
                key={index}
                className={index === value ? "job-btn active-btn" : "job-btn"}
                onClick={() => setValue(index)}
              >
                {item.attributes.company}
              </button>
            );
          })}
        </div>
        {/* job info */}
        <article className="job-info">
          <h3>{position}</h3>
          <h4>{company}</h4>
          <p className="job-date">{date}</p>
          {desc.map(item => {
            return (
              <div key={item.id} className="job-desc">
                <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
                <p>{item.name}</p>
              </div>
            );
          })}
        </article>
      </div>
      <Link to="/about" className="btn center-btn">
        more info
      </Link>
    </section>
  );
};

export default Jobs;
