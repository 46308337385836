import React from "react";
import { FaAngular, FaSketch, FaAndroid } from "react-icons/fa";
import { GrGatsbyjs } from "react-icons/gr";
import { GiTeacher } from "react-icons/gi";
const services = [
  {
    id: 1,
    icon: <GrGatsbyjs className="service-icon" />,
    title: "static website development",
    text: `The description is coming soon...`,
  },
  {
    id: 2,
    icon: <FaAngular className="service-icon" />,
    title: "web app design",
    text: `The description is coming soon...`,
  },
  {
    id: 3,
    icon: <GiTeacher className="service-icon" />,
    title: "consultancy/mentoring",
    text: `The description is coming soon...`,
  },
];

export default services;
